
.dark-mode {
    background-color: black;
    color: white;
}

.dark-mode .shadow {
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5)!important;
}

.dark-mode .text {
    color: rgb(255, 255, 255)!important;
}
